*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Poppins, Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1150px) {
  .container {
    max-width: 1150px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.z-20 {
  z-index: 20;
}

.z-10 {
  z-index: 10;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-32 {
  height: 8rem;
}

.h-14 {
  height: 3.5rem;
}

.h-8 {
  height: 2rem;
}

.min-h-min {
  min-height: -moz-min-content;
  min-height: min-content;
}

.w-screen {
  width: 100vw;
}

.w-24 {
  width: 6rem;
}

.w-4 {
  width: 1rem;
}

.w-full {
  width: 100%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-20 {
  width: 5rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.justify-self-center {
  justify-self: center;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 207 135 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.p-12 {
  padding: 3rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.font-sans {
  font-family: Poppins, Roboto, sans-serif;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-thin {
  font-weight: 100;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-white {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

:root {
  --bg-primary: #63cf87;
  --bg-primary-filter: #63cf8723;
  --bg-primary-filter-dark: #0e1d135b;
  --font-primary: #63cf87;
  --font-body: #242724;
  --font-dark: #2b2f312a;
  --darkpurpleprimary: #111827;
}

body {
  height: 100vh;
  overflow-y: auto;
}

.font-dark {
  color: var(--font-dark);
}

html {
  scroll-behavior: smooth;
  font-family: Poppins, Arial, "sans-serif";
}

h1.hero-header {
  color: #fff;
  font-size: 4.5rem;
  font-weight: 400;
}

@media (max-width: 767px) {
  h1.hero-header {
    font-size: 3.5rem;
  }
}

li, .logo, .nav-list {
  display: inline-block;
}

.nav-link {
  transition: all .3s;
}

.nav-link:hover {
  text-shadow: 0 5px 20px #8383835b;
  transform: translateY(-3px);
}

.nav-link:active {
  transform: translateY(-1px);
}

#nav-toggle-btn:active, .navbar-toggle-bg {
  background-color: #63cf87;
}

.top-nav__text {
  align-items: center;
  font-size: .7rem;
  transition: all .2s;
  display: flex;
}

@media only screen and (min-width: 1150px) {
  .top-nav__text {
    font-size: 1rem;
  }
}

.top-nav__text:hover {
  transform: translateY(2px);
}

.top-nav__icon {
  height: 1rem;
  width: 1rem;
  margin-right: .5rem;
}

button {
  color: #fff;
  background-color: #cf6663;
  border: 1px solid #0000;
  border-radius: 5px;
  padding: .5rem;
}

.bg-primary {
  background-color: var(--bg-primary);
}

.font-primary {
  color: var(--font-primary);
}

.font-body {
  color: var(--font-body);
  font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
}

.bg-cta {
  background-color: #ce3f3a;
}

.h-50 {
  background-color: gray;
}

.hero {
  width: 100%;
  height: 80vh;
}

.hero-wrapper {
  background: linear-gradient(0deg, var(--bg-primary-filter-dark), var(--bg-primary-filter)), url("hero_new.ef1e6b3e.webp") no-repeat center center;
  clip-path: ellipse(65% 90% at 50% 10%);
  background-size: cover;
}

@media (max-width: 1149px) {
  .hero-wrapper {
    clip-path: none;
  }
}

.cta-section {
  background-color: #fff;
  padding-top: 1rem;
  padding-bottom: 8rem;
}

.cta-box {
  box-shadow: 0 20px 50px var(--bg-primary-filter);
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 10px;
}

@media (min-width: 1150px) {
  .cta-box {
    transform: translateY(-100px);
  }
}

.cta-box p {
  font-size: 1.7rem;
  font-weight: 400;
}

.cta-box button {
  font-size: larger;
}

.list li {
  font-style: italic;
  line-height: 3rem;
  list-style-image: url("home-icon.ab36e015.webp");
  list-style-type: circle;
  display: list-item;
}

#welcome {
  background: linear-gradient(0deg, var(--bg-primary-filter-dark), var(--bg-primary-filter)), url("brook-g2fab6e2b5_1024-min.43b0c9bf.webp");
  background-size: cover;
  background-attachment: fixed;
}

#why-choose-us {
  background: linear-gradient(0deg, var(--bg-primary-filter-dark), var(--bg-primary-filter)), url("pexels-jocelyn-erskinekellie-10297555-min.a312b9b2.webp");
  background-size: cover;
  background-attachment: fixed;
}

.section-bg-img {
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
}

.icon {
  filter: invert(75%) sepia(13%) saturate(1414%) hue-rotate(87deg) brightness(94%) contrast(90%);
  width: 6rem;
}

@media (max-width: 768px) {
  .icon {
    width: 3rem;
  }
}

#visit-icon {
  margin-left: 36%;
}

#nav-bar, #logo {
  transition: all .8s;
}

.nav--hidden {
  transform: translateY(-170px);
}

@media (max-width: 1149px) {
  #nav-bar {
    box-shadow: 0 1px #202b4294;
  }
}

#no-hover-online-form-text, #no-hover-online-form-text:visited #no-hover-online-form-text:link {
  transition: none;
}

#no-hover-online-form-text:hover, #no-hover-online-form-text:active {
  box-shadow: none;
  transform: none;
}

.modal-btn, .modal-btn:visited, .modal-btn:link {
  transition: all .4s;
}

.modal-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px #4e4e4e79;
}

.modal-btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px #80808033;
}

.logo {
  width: 20%;
}

table {
  border-spacing: 20px;
}

th, td {
  padding: 1rem;
}

.togglefaq {
  margin-bottom: 1rem;
}

.togglefaq:focus + .faqanswer p {
  font-weight: 400;
}

@media (min-width: 768px) {
  .togglefaq:focus + .faqanswer p {
    font-weight: 500;
  }
}

.faqanswer {
  margin-bottom: 2rem;
  display: none;
}

.faqanswer p {
  line-height: 17px;
}

.icon-plus {
  color: var(--font-primary);
  float: left;
  margin-right: 20px;
  font-size: 2rem;
}

.icon-minus {
  color: gray;
  float: left;
  margin-right: 20px;
  font-size: 2rem;
}

.faq-fixed-height {
  height: 34rem;
  overflow: clip;
}

@media (max-width: 767px) {
  .faq-fixed-height {
    height: 40rem;
    overflow: hidden;
  }
}

#about {
  position: relative;
  top: -5rem;
}

#works, #why {
  position: relative;
  top: -4rem;
}

#faq {
  position: relative;
  top: -8rem;
}

.table-icon-fit {
  max-width: 100%;
  max-height: 100%;
}

.table-text-middle {
  text-align: center;
  align-items: center;
  display: flex;
}

hr {
  border-color: #6d6b6ba6;
}

.resolve-table-header {
  background-color: #fff;
  border: 1px solid #111827;
  border-radius: .75em;
  box-shadow: inset 0 0 10px #161f31;
}

.hr-steps {
  margin-top: 2rem;
}

#contact-form {
  visibility: hidden;
  height: 1px;
  display: block;
  position: relative;
  top: -50px;
}

.modal-container {
  box-sizing: border-box;
  z-index: 999;
  height: 100vh;
  background-color: #1b2539ec;
  padding-left: 5px;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal-container:target {
  pointer-events: all;
}

.modal {
  border-radius: 1.5rem;
  margin: 2rem auto 1rem;
  padding-left: 2px;
  padding-right: 2px;
  left: 0;
  right: 0;
}

@media (min-width: 1150px) {
  .modal {
    margin-top: 2.5rem;
  }
}

.modal-header, .modal-content {
  padding: 1rem;
}

.modal-content label {
  color: #363636;
  margin-bottom: .3rem;
  padding-left: .25rem;
  font-weight: 500;
}

input, textarea, select {
  color: var(--darkpurpleprimary);
  border: 1px solid #0000;
  border-radius: .75rem;
  padding-left: .25rem;
  font-weight: 500;
  line-height: 2rem;
}

select {
  height: 50%;
}

input:focus, textarea:focus, select:focus {
  background-color: #eff5ff;
  outline: #95ee95;
}

.modal-close-btn {
  z-index: 1000;
}

.close-container {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 10px;
  padding-left: 4px;
  position: relative;
}

.leftright {
  height: 4px;
  width: 40px;
  background-color: var(--darkpurpleprimary);
  border-radius: 2px;
  margin-top: 15px;
  transition: all .3s ease-in;
  position: absolute;
  transform: rotate(45deg);
}

.rightleft {
  height: 4px;
  width: 40px;
  background-color: var(--darkpurpleprimary);
  border-radius: 2px;
  margin-top: 15px;
  transition: all .3s ease-in;
  position: absolute;
  transform: rotate(-45deg);
}

.cross-container {
  margin-top: 10px;
}

.close {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0;
  padding: 40px 0 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: .6em;
  transition: all .3s ease-in;
}

.close-container:hover .leftright {
  background-color: #fff;
  transform: rotate(-45deg);
}

.close-container:hover .rightleft {
  background-color: #fff;
  transform: rotate(45deg);
}

.close-container:hover label {
  opacity: 1;
}

.why-figcap-text {
  line-height: 30px;
}

.privacy-sub-header {
  color: var(--font-primary);
  padding-top: 1rem;
  font-size: 1.5rem;
}

.thankyou-container {
  background: radial-gradient(circle, #63cf87 0%, #94d6aae0 100%);
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 207 135 / var(--tw-bg-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:pt-40 {
    padding-top: 10rem;
  }
}

@media (min-width: 768px) {
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:gap-14 {
    gap: 3.5rem;
  }

  .md\:gap-x-6 {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }

  .md\:font-normal {
    font-weight: 400;
  }
}

@media (min-width: 1150px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mr-80 {
    margin-right: 20rem;
  }

  .lg\:ml-80 {
    margin-left: 20rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-3\/6 {
    width: 50%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-40 {
    gap: 10rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .lg\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgb(99 207 135 / var(--tw-text-opacity));
  }
}

@media (min-width: 1440px) {
  .xl\:h-7 {
    height: 1.75rem;
  }

  .xl\:w-7 {
    width: 1.75rem;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:pt-80 {
    padding-top: 20rem;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem;
  }
}

/*# sourceMappingURL=output.bee4fd39.css.map */
